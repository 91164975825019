(function () {

  const $header = document.querySelector('.header');
  const $menu = document.querySelector('.header__layout');
  const $hamburger = document.querySelector('.burger');
  const $firstLevelLinks = document.querySelectorAll('.main-nav > .menu > .menu-item-has-children > a');
  const $secondLevelLinks = document.querySelectorAll('.main-nav .menu .sub-menu > .menu-item-has-children > a');
  const $backLink = $header.querySelector('.back-link');  let isMenuOpened = false;
  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened')
    $menu.classList.toggle('opened');
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;

    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
      $menu.classList.remove('second-menu-opened');
      $menu.classList.remove('third-menu-opened');
      $firstLevelLinks.forEach(item => {
        item.parentNode.classList.remove('opened');
      })
      $secondLevelLinks.forEach(item => {
        item.parentNode.classList.remove('opened');
      })
      $backLink.textContent = '';
      $backLink.classList.add('hidden');
    }
  });

  $firstLevelLinks.forEach(item => {
    item.addEventListener('click', function (e) {
      if (isMenuOpened) {
        e.preventDefault();
        const link = this.cloneNode(true);
        createBackLink(link, 2)
        $backLink.classList.remove('hidden');
        $menu.classList.add('second-menu-opened');
        this.parentNode.classList.add('opened');
      }
    })
  })

  $secondLevelLinks.forEach(item => {
    item.addEventListener('click', function (e) {
      if (isMenuOpened) {
        e.preventDefault();
        const link = this.cloneNode(true);
        createBackLink(link, 3)
        $menu.classList.add('third-menu-opened');
        this.parentNode.classList.add('opened');
      }
    })
  })

  function createBackLink(link, level) {
    link.classList.add(`level-${level}`);
    $backLink.appendChild(link);
  }

  $backLink.addEventListener('click', function () {
    if ($menu.classList.contains('third-menu-opened')) {
      $menu.classList.remove('third-menu-opened');
      $menu.classList.add('second-menu-opened');
      const link3 = $backLink.querySelector('.level-3');
      link3.remove();
      $secondLevelLinks.forEach(item => {
        item.parentNode.classList.remove('opened');
      })
    } else {
      $menu.classList.remove('second-menu-opened');
      const link2 = $backLink.querySelector('.level-2');
      link2.remove();
      $backLink.classList.add('hidden');
      $firstLevelLinks.forEach(item => {
        item.parentNode.classList.remove('opened');
      })
    }
  })
  window.addEventListener('resize', debounce(function () {
    if (isMenuOpened && window.innerWidth >= 768) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  }))

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();
