(function () {
  const truncatedBlocks = document.querySelectorAll('.truncated');
  truncatedBlocks.forEach(block => {
    const link = block.querySelector('.truncated__link');
    link.addEventListener('click', function (e) {
      e.preventDefault();
      block.classList.toggle('active');
      block.scrollIntoView({ behavior: "smooth" });
    })
  })
})()