(function () {
  const swiperNews = new Swiper('.member-slider', {
    slidesPerView: 1,
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 6000,
    },
    virtual: {
      enabled: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      slideChange: function (swiper) {
        const activeSlide = swiper.visibleSlides[0];
        if (activeSlide) {
          const card = activeSlide.querySelector('.member-card');
          if (!card.classList.contains('with-listener')) {
            card.addEventListener('click', function () {
              this.classList.toggle('flipped')
            })
            card.classList.add('with-listener');
          }
        }
        const prevSlideCard = document.querySelector('.member-slider .swiper-slide-prev .member-card');
        const nextSlideCard = document.querySelector('.member-slider .swiper-slide-next .member-card');
        if (prevSlideCard) {
          prevSlideCard.classList.remove('flipped')
        }
        if (nextSlideCard) {
          nextSlideCard.classList.remove('flipped')
        }
      }
    }
  });

})()